import Vue from "vue";

export default {
    getAccionesDisciplinarias(parametros) {
        return Vue.axios
            .get("llamado_atencion/find", { params: parametros })
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },
    getAccionDisciplinaria(id) {
        return Vue.axios
            .get("llamado_atencion/" + id)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },
    addAccionDisciplinaria(parametros) {
        return Vue.axios
            .post("llamado_atencion", parametros)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    },
    updateAccionDisciplinaria(parametros) {
        return Vue.axios
            .post("llamado_atencion/update", parametros)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    deleteAccionDisciplinaria(id) {
        return Vue.axios
            .delete("llamado_atencion/" + id)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    descargarReporte(parametros) {
        return Vue.axios({
            method: "GET",
            responseType: "blob",
            url: "reportes/accionesDisciplinarias",
            params: parametros,
        })
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
};
